import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import ErrorBoundary from 'components/common/ErrorBoundary';
import ReactGA from "react-ga4";
import {Toaster} from 'react-hot-toast';


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG_ID);

function App() {

  useEffect(() => {
    const handleContextMenu = (event) => {
        if (event.target.tagName === 'IMG') {
            event.preventDefault();
        }
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
        window.removeEventListener('contextmenu', handleContextMenu);
    };
}, []);
  return (
    <Router>
      <ErrorBoundary>
        <Toaster />
        <AppRoutes />
      </ErrorBoundary>
    </Router>
  );
}

export default App;
