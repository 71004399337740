import { axiosInterceptor } from 'utils/axiosInterceptor';
import logger from 'utils/logger';


export const loginAPI = async (credentials) => {
  try {
    logger.debug('Sending login request', credentials);
    const response = await axiosInterceptor.post(`/auth/login`, credentials);
    logger.info('Login API response received', response.data);
    return response.data;
  } catch (error) {
    logger.error('Login API request failed', error);
    throw error;
  }
};

export const registerAPI = async (userData) => {
  try {
    logger.debug('Sending registration request', userData);
    const response = await axiosInterceptor.post(`/auth/register`, userData);
    logger.info('Registration API response received', response.data);
    return response.data;
  } catch (error) {
    logger.error('Registration API request failed', error);
    throw error;
  }
};
