import Loader from 'components/common/Loader';
import RouteChangeTracker from 'components/googleanalytics/RouteChangeTracker';
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

const MainLayout = lazy(() => import('../layouts/MainLayout'));
const Home = lazy(() => import('../pages/Home'));
const AboutUs = lazy(() => import('../pages/AboutUs'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Login = lazy(() => import('../pages/Login'));
const Signup = lazy(() => import('../pages/SignUp'));
const NotFound = lazy(() => import('../pages/NotFound'));
const IndianStyle = lazy(() => import('../pages/weddingstyles/IndianStyle'));
const WesternStyle = lazy(() => import('../pages/weddingstyles/WesternStyle'));
const Pricing = lazy(() => import('../pages/Pricing'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));
const StoreDesign = lazy(() => import('../pages/marketplace/StoreDesign'));
const Marketplace = lazy(() => import('../pages/marketplace/Marketplace'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const Terms = lazy(() => import('../pages/Terms'));
const Contact = lazy(() => import('../pages/Contact'));
const VerifyPayment = lazy(() => import('../pages/payment/VerifyPayment'));

const AppRoutes = () => {

  const {user} = useSelector(state => state.auth);

  return (
    <Suspense fallback={<Loader />}>
      <RouteChangeTracker />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={user? <Navigate to="/dashboard"/>:<Home/>}  />
          <Route element={<AuthLayout />}>
            <Route path="/dashboard" element={<Dashboard />}  />
            <Route path="/store/design/:id" element={<StoreDesign />} />
            <Route path="/verify_payment" element={<VerifyPayment />} />
          </Route>
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/wedding" element={<Navigate to="/wedding/indian" />} />
          <Route path="/wedding/indian" element={<IndianStyle />}/>
          {/* <Route path="/wedding/western" element={<WesternStyle />}/> */}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
