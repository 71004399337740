import log from 'loglevel';

log.setLevel(process.env.NODE_ENV === 'development' ? 'debug' : 'warn');

const logger = {
  debug: (...args) => log.debug(...args),
  info: (...args) => log.info(...args),
  warn: (...args) => log.warn(...args),
  error: (...args) => log.error(...args),
};

export default logger;
