import PropTypes from 'prop-types';
import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const Loader = ({ loaderBackgroundClass }) => {
  if (loaderBackgroundClass === undefined) 
    loaderBackgroundClass = "h-screen bg-gray-900";

  return (
    <div className={`flex flex-col items-center justify-center text-center ${loaderBackgroundClass}`}>
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#4fa94d"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <p className="mt-4 text-lg text-gray-400">Please wait, content is loading...</p>
    </div>
  )
}

Loader.propTypes = {
  loaderBackgroundClass : PropTypes.string
};

export default Loader