import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginAPI, registerAPI } from './authAPI';
import logger from 'utils/logger';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';

export const loginUser = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
  try {
    const response = await loginAPI(credentials);
    localStorage.setItem('tla_at', response.data.access_token);
    localStorage.setItem('tla_rt', response.data.refresh_token);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.errors || 'Login failed');
  }
});

export const registerUser = createAsyncThunk('auth/register', async (userData, thunkAPI) => {
  try {
    const response = await registerAPI(userData);
    return response;
  } catch (error) {
    logger.error('Registration failed', error);
    let errMsg = "";
    if (error.response?.data?.errors && error.response.data.errors.length > 0) {
      errMsg = error.response.data.errors[0].msg;
    }
    return thunkAPI.rejectWithValue(errMsg || 'Registration failed');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    logout(state) {
      state.user = null;
      localStorage.removeItem('tla_at');
      localStorage.removeItem('tla_rt');
      toast.success("Logout successfully")
    },
    setUser(state, action) {
      try {
        state.user = jwtDecode(action.payload);
      } catch {
        state.user = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        logger.debug('Login request initiated');
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        logger.info('Login successful');
        state.isLoading = false;
        state.user = jwtDecode(action.payload.data.access_token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        logger.warn('Login failed', action.payload);
        state.isLoading = false;
        logger.debug("THIS IS REDUCER", action.payload);
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        logger.debug('Registration request initiated');
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        logger.info('Registration successful');
        state.isLoading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        logger.warn('Registration failed', action.payload);
        state.isLoading = false;
        state.error = action.payload;

      });
  },
});

export const { logout, setUser } = authSlice.actions;
export default authSlice.reducer;
