import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {},
  isLoading: false,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action) {
      const { payload } = action;
      state.profile = payload;
    },
    clearProfile(state) {
      state.profile = {};
    },
  },
});

export const { setProfile, clearProfile } = profileSlice.actions;
export default profileSlice.reducer;
