import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [], 
  isLoading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchDataSuccess(state, action) {
      const { payload } = action;
      state.data = payload;
    },
    clearData(state) {
      state.data = [];
    },
  },
});

export const { fetchDataSuccess, clearData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
